import { Component, OnInit } from '@angular/core';
import {PatientVisit} from "../../../models/patient-visit";
import {RestService} from "../../../services/rest.service";
import {Globals} from "../../../models/globals";
import {Response} from "../../../models/response";
import {Patient} from "../../../models/patient";
import {Router} from "@angular/router";

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {

  public patients: Patient[];
  public date;
  public loading = false;
  public deleteId = 0;
  public modalPatientId = 0;
  public deleteHead = '';
  public deleteText = '';
  public createVisitId = 0;
  public createVisitHead = '';
  public createVisitText = '';
  public patientVisit: PatientVisit = new PatientVisit();

  config = {
    firstDayOfWeek: 'su',
    monthFormat: 'MMM, YYYY',
    disableKeypress: false,
    allowMultiSelect: false,
    closeOnSelect: undefined,
    closeOnSelectDelay: 100,
    onOpenDelay: 0,
    weekDayFormat: 'ddd',
    appendTo: document.body,
    drops: 'down',
    opens: 'right',
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    format: 'YYYY-MM-DD HH:mm',
    yearFormat: 'YYYY',
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ':',
    multipleYearsNavigateBy: 10,
    showMultipleYearsNavigation: false,
    locale: 'zh-cn',
    // min:'2017-08-29 15:50',
    // minTime:'2017-08-29 15:50'
  };
  showActionsModal: false;
  actionsModalTop: string;
  actionsModalLeft: string;
  actionsModalWidth: string;

  constructor(private rest: RestService, private router: Router, globals: Globals) {
    globals.showMenu = true;
    globals.showHeader = true;
    this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + new Date().getDate();

    globals.title = 'Պացիենտներ(Քարտեր)';
    globals.showAddButton = true;
    globals.addButtonUrl = '/patients/create';
    this.loading = true;

    this.rest.get('patients', [], new Response<Patient>()).then(
      resp => resp.subscribe(
        data => {
          this.patients = data.items;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      ));
  }

  ngOnInit(): void {
  }

  search(event: any) {
    // if (event.keyCode === 13) {
    if (!event.target.value.length || event.target.value.length === 0 || event.target.value.length >= 3) {
      this.loading = true;
      this.rest.get(`patients?q=${event.target.value}`, [], new Response<Patient>()).then(
        resp => resp.subscribe(
          data => {
            this.patients = data.items;
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        ));
    }
  }

  deleteAction(patient){
    this.deleteId = patient.id;
    this.deleteHead = `${patient.name} ${patient.surname}`;
    this.deleteText = `Վստահ եք որ ուզում եք ջնջել ${patient.name} ${patient.surname} պացիենտի քարտը`;
  }

  confirmDelete(){
    this.rest.delete(`patients/${this.deleteId}/delete`, [], {}).then(
      resp => resp.subscribe(
        data => {
          document.getElementById(this.deleteId.toString()).remove();
          this.deleteHead = '';
          this.deleteText = '';
          this.deleteId = null;
          this.modalPatientId = 0;
        },
        error => {
          this.deleteHead = '';
          this.deleteText = '';
          this.deleteId = null;
          this.modalPatientId = 0;
        }
      )
    );
  }

  openCreateVisitModal(patient){

    this.router.navigate([`/patient/visits/create`], {queryParams: {patientid: patient.id}});
    return;
    this.createVisitId = patient.id;
    this.createVisitHead = `${patient.name} ${patient.surname}`;
    this.createVisitText = `Ամրագրել ժամ  ${patient.name} ${patient.surname} պացիենտի համար`;
  }

  createVisit(){
    const formData = new FormData();

    for ( const key in this.patientVisit ) {
      formData.append(key, this.patientVisit[key]);
    }
    console.log(this.patientVisit);
    this.rest.post(`patient/visits`, formData, [], new Response<PatientVisit>()).then(
      resp => resp.subscribe(
        data => {
          document.getElementById(this.deleteId.toString()).remove();
          this.deleteHead = '';
          this.deleteText = '';
          this.deleteId = null;
        },
        error => {
          this.deleteHead = '';
          this.deleteText = '';
          this.deleteId = null;
        }
      )
    );
  }

  openCloseActionModal(modalPatientId, event = null) {
    if (modalPatientId !== this.modalPatientId) {
      if (event) {
        const el = event.target.getBoundingClientRect();
        this.actionsModalTop = (el.top + el.height + 10).toString() + 'px';
        this.actionsModalLeft = (el.left - 100 + el.width).toString() + 'px';
        // this.actionsModalWidth = el.width + 'px';
      }
      this.modalPatientId = modalPatientId;
    }
    else {
      this.modalPatientId = 0;
    }
  }
}
