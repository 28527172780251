<!--
  <div id="loader" *ngIf="loading" >
    <img src="../../../../assets/images/logo-icon.png" alt="icon" class="loading-icon">
  </div>

  <!- Transactions ->
  <div class="section mt-2">

    <form>
      <div class="form-group basic animated">
        <div class="input-wrapper">
          <label class="label" for="userid2">Որոնել</label>
          <input type="text" class="form-control" id="userid2" placeholder="Որոնել" (keyup)="search($event)">
          <i class="clear-input"></i>
        </div>
      </div>
    </form>

    <!-<div class="section-title">{{date}}</div>->
    <div [id]="patientVisit.id" class="transactions" *ngFor="let patientVisit of patientVisits" style="margin-top: 10px">
      <a class="item">
        <div class="detail">
          <div>
            <strong>{{patientVisit.patient.name}} {{patientVisit.patient.surname}}</strong>
            <p>{{patientVisit.start_time}} - {{patientVisit.end_time}}</p>
          </div>
        </div>
        <div class="right">
          <div class="price text-danger">
            <i class="fa fa-trash" (click)="deleteAction(patientVisit)" aria-hidden="true" style="font-size: 25px;"></i>
          </div>
        </div>
      </a>
    </div>
  </div>
  <!- * Transactions ->

  <!- * Transactions

  <div class="section mt-2 mb-2">
    <a href="javascript:;" class="btn btn-primary btn-block btn-lg">Load More</a>
  </div>->

  <div class="modal fade dialogbox show" id="DialogBasic" data-backdrop="static"
       tabindex="-1" role="dialog" aria-modal="true" style="display: block;" *ngIf="deleteId">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{deleteHead}}</h5>
        </div>
        <div class="modal-body">
          {{deleteText}}
        </div>
        <div class="modal-footer">
          <div class="btn-inline">
            <a href="#" class="btn btn-text-secondary" data-dismiss="modal" (click)="deleteId = null">Չեղարկել</a>
            <a href="#" class="btn btn-text-primary" data-dismiss="modal" (click)="confirmDelete()">Հաստատել</a>
          </div>
        </div>
      </div>
    </div>
  </div>
-->

<div class="container">
  <div class="spacing-less">

    <div class="top-nav"><img class="small-logo" src="/assets/images/logo.svg" alt="">
      <a class="icon-button icon-profile"></a>
    </div>

    <div class="container-descirption">
      <h2>Օրացույց</h2>

      <div class="calendar-block">
        <div class="calendar-day {{day.isActive ? 'active' : ''}}" *ngFor="let day of days" (click)="changeDate(day)">
          <p class="calendar-month" *ngIf="day.monthText">{{day.monthText}}</p>
          <p class="calendar-date">{{day.day}}</p>
          <p class="calendar-dayname">{{day.weekDay}}</p>
        </div>
      </div>

    </div>
  </div>
</div>

<!--
<div [id]="patientVisit.id" class="transactions" *ngFor="let patientVisit of patientVisits" style="margin-top: 10px">
      <a class="item">
        <div class="detail">
          <div>
            <strong>{{patientVisit.patient.name}} {{patientVisit.patient.surname}}</strong>
            <p>{{patientVisit.start_time}} - {{patientVisit.end_time}}</p>
          </div>
        </div>
        <div class="right">
          <div class="price text-danger">
            <i class="fa fa-trash" (click)="deleteAction(patientVisit)" aria-hidden="true" style="font-size: 25px;"></i>
          </div>
        </div>
      </a>
    </div>-->

<div class="container vh100">
  <div *ngIf="patientVisits.length > 0" class="spacing flex-1 corners grey">
    <!--<a class="block-link" href="#"></a>-->
    <div class="appointment" *ngFor="let patientVisit of patientVisits">
      <a class="block-link" href="#">
        <div>
          <p class="detail-description">{{patientVisit.treatment}}</p>
          <p class="detail-lable">{{patientVisit.patient.name}} {{patientVisit.patient.surname}} ·
            <span>{{formatTime(patientVisit.start_time)}} - {{formatTime(patientVisit.end_time)}}</span></p>
        </div>
      </a>
      <a class="icon-button icon-more" (click)="openCloseActionModal(patientVisit.id, $event)">
      </a>
    </div>
  </div>

  <div *ngIf="!patientVisits || patientVisits.length == 0" class="spacing flex-1 corners grey">
    <div class="container-info pb24">
      <div class="image-center">
        <img src="./assets/images/calendar.png" alt="">
      </div>
      <h2>Գրանցումներ չեն գնտնվել</h2>
      <p>Ներկա պահին դուք չունեք գրանցված այցելություններ ձեր օրացույցում։</p>
    </div>
  </div>

  <div class="actions spacing flex-1 corners white" *ngIf="modalPatientVisitId != 0"
       [ngStyle]="{'top': actionsModalTop,'left': actionsModalLeft}">
    <!--<i class="icon-close fr" (click)="openCloseActionModal(0)"></i>-->

    <a class="secondary-button mb16 mt0 background-white ra" routerLink="/patients/{{modalPatientVisitId}}/edit">
      <!--<i class="icon-add"></i>--> Edit
    </a>

    <a class="block-link delete" (click)="deleteId = modalPatientVisitId; confirmDelete()">
      <!--<i class="icon-add"></i>--> Delete
    </a>

    <!--<div *ngIf="deleteId">
      <a (click)="deleteId = null"><i class="icon-clear" aria-hidden="true"></i></a>
      <a (click)="confirmDelete()"><i class="icon-call"></i></a>
    </div>-->
  </div>

  <nav>
    <div class="nav">
      <div class="nav-link active"><i class="icon-calendar"></i> Օրացույց</div>
      <a class="nav-link" [routerLink]="['/patient/visits/create']" [queryParams]='{date: selectedDate}'><i class="icon-add icon-button"></i></a>
      <a class="nav-link" routerLink="/patients"><i class="icon-notebook"></i> Անկետաներ</a>
    </div>
  </nav>
</div>

