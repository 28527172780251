import {ModuleWithProviders, NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./modules/auth/login/login.component";
import {AuthGuardService} from "./services/auth-guard.service";
import {PatientVisitComponent} from "./modules/patient/patient-visit/patient-visit.component";


const routes: Routes = [
  {
    path: '',
    component: PatientVisitComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: false });
