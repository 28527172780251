<!--<div id="appCapsule">

  <div id="loader" *ngIf="loading">
    <images src="assets/images/logo-icon.png" alt="icon" class="loading-icon">
  </div>

  <div class="section mt-2">

    <form>
      <div class="form-group basic animated">
        <div class="input-wrapper">
          <label class="label" for="userid2">Որոնել</label>
          <input type="text" class="form-control" id="userid2" placeholder="Որոնել" (keyup)="search($event)">
          <i class="clear-input"></i>
        </div>
      </div>
    </form>

    <!-<div class="section-title">{{date}}</div>->
    <div [id]="patient.id" class="transactions" *ngFor="let patient of patients" style="margin-top: 10px">
      <a class="item">
        <div class="detail" style="width: 60%; overflow: hidden;">
          <div>
            <strong>{{patient.name}} {{patient.surname}}</strong>
            <p>{{patient.phone}}</p>
          </div>
        </div>
        <div class="right">
          <div class="price text-danger">
            <i class="fas fa-clock" style="margin-right: 12px; font-size: 25px; color:green"
               (click)="openCreateVisitModal(patient)"></i>
            <i class="fas fa-edit" style="margin-right: 10px; font-size: 25px; color:#6236FF"
               [routerLink]="'/patients/' + patient.id + '/edit'"></i>
            <i class="fa fa-trash" (click)="deleteAction(patient)" aria-hidden="true" style="font-size: 25px;"></i>

            <!-<button type="button" class="btn btn-outline-danger mr-1 mb-1">Ջնջել</button>->
          </div>
        </div>
      </a>
    </div>
  </div>
  <!- * Transactions ->

  <!- * Transactions

  <div class="section mt-2 mb-2">
    <a href="javascript:;" class="btn btn-primary btn-block btn-lg">Load More</a>
  </div>->

  <div class="modal fade dialogbox show" id="DialogBasic" data-backdrop="static"
       tabindex="-1" role="dialog" aria-modal="true" style="display: block;" *ngIf="deleteId">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{deleteHead}}</h5>
        </div>
        <div class="modal-body">
          {{deleteText}}
        </div>
        <div class="modal-footer">
          <div class="btn-inline">
            <a class="btn btn-text-secondary" data-dismiss="modal" (click)="deleteId = null">Չեղարկել</a>
            <a class="btn btn-text-primary" data-dismiss="modal" (click)="confirmDelete()">Հաստատել</a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade dialogbox show" id="DialogCreateVisit" data-backdrop="static"
       tabindex="-1" role="dialog" aria-modal="true" style="display: block;" *ngIf="createVisitId">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{createVisitHead}}</h5>
        </div>
        <div class="modal-body">
          {{createVisitText}}

          <div class="form-group">
            <dp-day-time-calendar [(ngModel)]="patientVisit.end_time" [config]="config"></dp-day-time-calendar>
            <dp-date-picker class="form-control" [(ngModel)]="patientVisit.day" [config]="config"
                            style="margin-top:15px"></dp-date-picker>
            <input class="form-control" [(ngModel)]="patientVisit.start_time" style="margin-top:15px">
            <dp-time-select [(ngModel)]="patientVisit.id" [config]="config"></dp-time-select>
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn-inline">
            <a class="btn btn-text-secondary" data-dismiss="modal" (click)="createVisitId = null">Չեղարկել</a>
            <a class="btn btn-text-primary" data-dismiss="modal" (click)="createVisit()">Հաստատել</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="container">
  <div class="spacing-less">
    <div class="top-nav">
      <img class="small-logo" src="/assets/images/logo.svg" alt="" />
      <a class="icon-button icon-profile"></a>
    </div>

    <div class="container-descirption">
      <h2>Անկետաներ</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="spacing flex-1 corners grey">
    <div class="search-block">
      <input class="white" type="search" placeholder="Փնտրել պացիենտին" (keyup)="search($event)">
      <i class="icon-search"> </i>
    </div>

    <a [id]="patient.id" class="block-link" *ngFor="let patient of patients">
      <div class="questionnaire">
        {{patient.name}} {{patient.surname}} {{patient.phone}}
        <i class="icon-button icon-more" (click)="openCloseActionModal(patient.id, $event)"></i>
      </div>
    </a>
  </div>

  <!--<div class="modal fade dialogbox show" id="DialogBasic" data-backdrop="static"
       tabindex="-1" role="dialog" aria-modal="true" style="display: block;" *ngIf="deleteId">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{deleteHead}}</h5>
        </div>
        <div class="modal-body">
          {{deleteText}}
        </div>
        <div class="modal-footer">
          <div class="btn-inline">
            <a class="btn btn-text-secondary" data-dismiss="modal" (click)="deleteId = null">Չեղարկել</a>
            <a class="btn btn-text-primary" data-dismiss="modal" (click)="confirmDelete()">Հաստատել</a>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div class="actions spacing flex-1 corners white" *ngIf="modalPatientId != 0"
       [ngStyle]="{'top': actionsModalTop,'left': actionsModalLeft}">
    <!--<i class="icon-close fr" (click)="openCloseActionModal(0)"></i>-->

    <a class="secondary-button mb16 mt0 background-white ra" routerLink="/patients/{{modalPatientId}}/edit">
      <!--<i class="icon-add"></i>--> Edit
    </a>

    <a class="block-link delete" (click)="deleteId = modalPatientId; confirmDelete()">
      <!--<i class="icon-add"></i>--> Delete
    </a>

    <!--<div *ngIf="deleteId">
      <a (click)="deleteId = null"><i class="icon-clear" aria-hidden="true"></i></a>
      <a (click)="confirmDelete()"><i class="icon-call"></i></a>
    </div>-->
  </div>

  <nav>
    <div class="nav">
      <a class="nav-link" routerLink="/patient/visit"><i class="icon-calendar"></i> Օրացույց</a>
      <a class="nav-link" routerLink="/patients/create"><i class="icon-add icon-button"></i></a>
      <a class="nav-link active" routerLink="/patients"><i class="icon-notebook"></i> Անկետաներ</a>
    </div>
  </nav>

</div>

