<!--<div id="appCapsule">

  <div id="loader" *ngIf="loading" >
    <img src="../../../../assets/images/logo-icon.png" alt="icon" class="loading-icon">
  </div>

  <div class="section mt-2">
    <div class="section-title">Նոր քարտ</div>
    <div class="card">
      <div class="card-body">
        <div class="mb-05">
          <!-Type something to activate animation.->
        </div>
        <form>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <label class="label" for="name">Անուն</label>
              <input type="text" class="form-control" [(ngModel)]="patient.name" id="name" name="name" placeholder="Անուն">
              <i class="clear-input">
                <!-<ion-icon< name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <label class="label" for="surname">Ազգանուն</label>
              <input type="text" class="form-control" [(ngModel)]="patient.surname" id="surname" name="surname" placeholder="Ազգանուն">
              <i class="clear-input">
                <!-<ion-icon name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <label class="label" for="fathername">Հայրանուն</label>
              <input type="text" class="form-control" id="fathername" name="fathername" [(ngModel)]="patient.fathername" placeholder="Հայրանուն">
              <i class="clear-input">
                <!-<ion-icon name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <label class="label" for="phone">Հեռախոս</label>
              <input type="text" class="form-control" [(ngModel)]="patient.phone" id="phone" name="phone" placeholder="Հեռախոս">
              <i class="clear-input">
                <!-<ion-icon name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>

          <button type="button" class="btn btn-success btn-block" style="margin-top: 10px" (click)="save()">Պահպանել</button>
        </form>

      </div>
    </div>
  </div>
</div>
-->


<div class="container blue">
  <div class="spacing-less">

    <div class="top-nav-close"><a routerLink="/patients" class="icon-button opacity icon-close-white"></a></div>

    <div class="container-descirption">
      <h2>Գրանցել Անկետա</h2>
    </div>
  </div>
</div>

<div class="container blue vh100">
  <div class="spacing flex-1 corners white">
    <form class="form-fields">

      <label>ԱԱՀ<span>*</span></label>
      <input type="text" id="fullname" name="full-name"  [(ngModel)]="patient.nsf"
             [ngClass]="{'validation-error': errors.nsf}" (keyup)="change(patient.nsf, 'nsf')">

      <label>Ծննդյան ամսաթիվ 13.05.1984</label>
      <div class="inputsdob">
        <input type="text" name="day" placeholder="Օր" [(ngModel)]="patient.birthday_day">
        <input type="text" name="month" placeholder="Ամիս" [(ngModel)]="patient.birthday_month">
        <input type="text" name="year" placeholder="Տարի" [(ngModel)]="patient.birthday_year">
      </div>

      <label>Հեռախոսահամար<span>*</span></label>
      <input type="text" name="main-number" [(ngModel)]="patient.phone"
             [ngClass]="{'validation-error': errors.phone}"  (keyup)="change(patient.phone, 'phone')">

      <!--<label>Հավելյալ Հեռախոսահամար</label>
      <input type="number" [(ngModel)]="patient.phone" id="phone" name="phone">-->

      <!--<label>էլ․ հասցե </label>
      <input type="email" name="email" [(ngModel)]="patient">-->

      <label>Նշումներ</label>
      <input type="text" name="notes">
      <!--<p class="error-message">Error</p>-->

      <button type="submit" (click)="save()">Գրանցել</button>

    </form>
  </div>
</div>
