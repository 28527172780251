import { Component, OnInit } from '@angular/core';
import {Glob} from "@angular/service-worker/config";
import {Globals} from "../../models/globals";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public config: Globals) { }

  ngOnInit(): void {
  }

}
