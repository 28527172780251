export class Patient {
  id: number;
  nsf: string;
  name: string;
  surname: string;
  fathername: string;
  phone: string;
  birthday_day: string;
  birthday_month: string;
  birthday_year: string;
}
