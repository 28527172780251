import { Component, OnInit } from '@angular/core';
import {Globals} from "../../models/globals";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  host: {'class': 'container'}
})
export class HeaderComponent implements OnInit {

  constructor(public config: Globals) { }

  ngOnInit(): void {
  }

}
