import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from "./login/login.component";
import {RouterModule} from "@angular/router";
import {routing} from "./auth.routing.module";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [LoginComponent],
    imports: [
        CommonModule,
        routing,
        FormsModule
    ],
  exports:[
    RouterModule
  ]
})
export class AuthModule { }
