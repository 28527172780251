import { Component, OnInit } from '@angular/core';
import {RestService} from "../../../services/rest.service";
import {Login} from "../../../models/login";
import {Response} from "../../../models/response";
import {TokenResponse} from "../../../models/token-response";
import {PatientVisit} from "../../../models/patient-visit";
import { Globals } from 'src/app/models/globals';
import {Patient} from "../../../models/patient";
import {Day} from "../../../models/day";
import * as moment from "moment";

@Component({
  selector: 'app-patient-visit',
  templateUrl: './patient-visit.component.html',
  styleUrls: ['./patient-visit.component.css'],
  host: {'class': 'container vh100'}
})

export class PatientVisitComponent implements OnInit {

  public patientVisits: PatientVisit[];
  public date;
  public loading = false;
  public deleteId = 0;
  public deleteHead = '';
  public deleteText = '';
  public days: Day[];
  public currentDay: Day;
  public selectedDate = '';
  public modalPatientVisitId = 0;
  actionsModalTop: string;
  actionsModalLeft: string;
  public weekDays = ['Կր', 'Եկ', 'Եք', 'Չո', 'Հի', 'Ու', 'Շա'];
  public months = ['Հունվար', 'Փետրվար', 'Մարտ', 'Ապրիլ', 'Մայիս', 'Հունիս',
    'Հուլիս', 'Օգոստոս', 'Սեպտեմբեր', 'Հոկտեմբեր', 'Նոյեմբեր', 'Դեկտեմբեր'];

  constructor(private rest: RestService, globals: Globals) {
    /*globals.showMenu = true;
    globals.showHeader = true;
    globals.showAddButton = true;
    globals.addButtonUrl = '/patient/visits/create';*/

    this.patientVisits = [];

    this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + new Date().getDate();

    this.days = new Array<Day>();

    for (let i = 0; i < 14; i++){
      const day = new Day();
      const today = new Date();
      today.setDate(today.getDate() + i);

      day.date = today;
      day.day = today.getDate();
      day.weekDay = this.weekDays[today.getDay()];

      if (i === 0 || day.day === 1) {
        day.monthText = this.months[today.getMonth()];
      }
      this.days.push(day);

    }

    this.days[0].isActive = true;
    this.currentDay = this.days[0];

    globals.title = 'Այցելություններ | '
      + new Date().getDate()
      + ' ' + globals.months[new Date().getMonth()]
      + ' ' + new Date().getFullYear();

    this.loading = true;
    this.rest.get('patient/visits?date=' + moment(this.days[0].date).format('YYYY-MM-DD'), [], new Response<PatientVisit>()).then(
      resp => resp.subscribe(
        data => {
          this.patientVisits = data.items;
          this.loading = false;
        },
          error => {
            this.loading = false;
          }
    ));
  }

  ngOnInit(): void {
  }

  changeDate(day: Day){
    for (let i = 0; i < this.days.length; i++){
      this.days[i].isActive = false;
    }
    day.isActive = true;

    this.currentDay = day;
    const date = moment(day.date).format('YYYY-MM-DD');
    this.selectedDate = date;

    this.loading = true;
    this.rest.get('patient/visits?date=' + date, [], new Response<PatientVisit>()).then(
      resp => resp.subscribe(
        data => {
          this.patientVisits = data.items;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      ));
  }

  search(event: any) { // without type info
    if (event.keyCode === 13) {
      this.loading = true;
      this.rest.get(`patient/visits?q=${event.target.value}`, [], new Response<PatientVisit>()).then(
        resp => resp.subscribe(
          data => {
            this.patientVisits = data.items;
            this.loading = false;
          },
          error => {
            this.loading = false;

          }
        ));
    }
  }

  deleteAction(patientVisit){
    this.deleteId = patientVisit.id;
    this.deleteHead = `${patientVisit.patient.name} ${patientVisit.patient.surname}`;
    this.deleteText = `Վստահ եք որ ուզում եք ջնջել ${patientVisit.start_time} - ${patientVisit.end_time} այցելությունը`;
  }

  confirmDelete(){
    this.rest.delete(`patient/visits/${this.deleteId}/delete`, [], {}).then(
      resp => resp.subscribe(
        data => {
          document.getElementById(this.deleteId.toString()).remove();
          this.deleteHead = '';
          this.deleteText = '';
          this.deleteId = null;
        },
        error => {
          this.deleteHead = '';
          this.deleteText = '';
          this.deleteId = null;
        }
      )
    );
  }

  formatTime(time: number){
    const minute = time % 100;
    const hour = Math.floor(time / 100);

    return (hour.toString().length === 1 ? '0' + hour.toString() : hour.toString()) + ':'
      + (minute.toString().length === 1 ? '0' + minute.toString() : minute.toString());

  }

  openCloseActionModal(modalPatientVisitId, event = null) {
    if (modalPatientVisitId !== this.modalPatientVisitId) {
      if (event) {
        const el = event.target.getBoundingClientRect();
        this.actionsModalTop = (el.top + el.height + 10).toString() + 'px';
        this.actionsModalLeft = (el.left - 100 + el.width).toString() + 'px';
        // this.actionsModalWidth = el.width + 'px';
      }
      this.modalPatientVisitId = modalPatientVisitId;
    }
    else {
      this.modalPatientVisitId = 0;
    }
  }
}
