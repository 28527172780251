export class Globals {
  title: string;
  showAddButton: boolean;
  showBackButton: boolean;
  addButtonUrl: string;
  backButtonUrl: string;
  showMenu = true;
  showHeader = true;
  mainClass: string;
  isHome: boolean;
  isLogin: boolean;
  search: boolean;
  searchValue: string;

  months = [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր'
  ];
}
