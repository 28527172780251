import {Patient} from "./patient";

export class PatientVisit {
  id: number;
  day: Date;
  start_time: number;
  end_time: number;
  state: string;
  service: string;
  treatment: string;
  description: string;
  patient_id: number;
  patient: Patient;
}
