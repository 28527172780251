import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {AppRoutingModule, routing} from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {RouterModule} from "@angular/router";
import {AuthModule} from "./modules/auth/auth.module";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, DatePipe, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {CarouselModule} from "ngx-owl-carousel-o";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PatientVisit} from "./models/patient-visit";
import {PatientModule} from "./modules/patient/patient.module";
import { HeaderComponent } from './components/header/header.component';
import {Globals} from "./models/globals";
import { FooterComponent } from './components/footer/footer.component';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    AuthModule,
    PatientModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    routing,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],

  exports: [
    RouterModule,
    HttpClientModule
  ],
  providers: [
    Globals,
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
