<!--<div id="appCapsule">

  <div id="loader" *ngIf="loading" >
    <img src="../../../../assets/images/logo-icon.png" alt="icon" class="loading-icon">
  </div>

  <div class="section mt-2">
    <div class="section-title">Գրանցել այց</div>
    <div class="card">
      <div class="card-body">
        <div class="mb-05">
          <!-Type something to activate animation.->
        </div>
        <form>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <label class="label" for="patient">Պացիենտ</label>
              <select class="form-control" [(ngModel)]="patientVisit.patient_id" id="patient" name="patient">
                <option *ngFor="let p of patients" [value]="p.id">{{p.name}} {{p.surname}} {{p.phone}}</option>
              </select>
              <i class="clear-input">
                <!-<ion-icon name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <input matInput [(ngModel)]="patientVisit.day" name="day" [matDatepicker]="picker" class="form-control">
              <mat-datepicker-toggle matSuffix [for]="picker" style="position: absolute; top: 0px; left: 87%;"></mat-datepicker-toggle>
              <mat-datepicker #picker form></mat-datepicker>
              <!-<label class="label" for="datepicker">Օր</label>

              <dp-date-picker id="datepformaticker" theme="dp-material " [(ngModel)]="patientVisit.day"
                               [config]="config" name="end_time"
                               [minDate]='minDate' minDate="{{minDate}}" [displayDate]="minDate"></dp-date-picker>->
              <i class="clear-input">
                <!-<ion-icon< name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>

          <div class="form-group basic animated">
            <div class="input-wrapper not-empty">
              <label class="label" for="start_time">Սկիզբ</label>
              <select class="form-control" [(ngModel)]="patientVisit.start_time" id="start_time" name="start_time">
                <option *ngFor="let t of times" [value]="t">{{t}}</option>
              </select>
              <i class="clear-input">
                <!-<ion-icon name="close-circle"></ion-icon>->
              </i>
            </div>
          </div>
          <div class="form-group basic animated">
          <div class="input-wrapper not-empty">
            <label class="label" for="end_time">Ավարտ</label>
            <select class="form-control" [(ngModel)]="patientVisit.end_time" id="end_time" name="end_time">
              <option *ngFor="let t of times" [value]="t">{{t}}</option>
            </select>
            <i class="clear-input">
              <!-<ion-icon name="close-circle"></ion-icon>->
            </i>
          </div>
        </div>

          <button type="button" class="btn btn-success btn-block" style="margin-top: 10px" (click)="save()">Պահպանել</button>
        </form>

      </div>
    </div>
  </div>
</div>-->

<div class="container blue">
  <div class="spacing-less">

    <div class="top-nav-close">
      <a class="icon-button opacity icon-close-white" routerLink="/patient/visit"></a>
    </div>

    <div class="container-descirption">
      <!--<span class="step">Քայլ 1/3</span>-->
      <h2>Գրանցել Այցելություն</h2>
    </div>
  </div>
</div>

<div class="container blue">
  <div class="spacing flex-1 corners white">

    <div class="calendar-block mt0">
      <div class="calendar-day {{day.isActive ? 'active' : ''}}" *ngFor="let day of days" (click)="changeDate(day)">
        <p class="calendar-month" *ngIf="day.monthText">{{day.monthText}}</p>
        <p class="calendar-date">{{day.day}}</p>
        <p class="calendar-dayname">{{day.weekDay}}</p>
      </div>
    </div>

    <form class="form-fields">
      <div class="inputstime">
        <div class="time">
          <label>Սկիզբ</label>
          <select name="start_time" [(ngModel)]="patientVisit.start_time"
                  [ngClass]="{'validation-error': errors.startTime}"
                  (change)="changeStartTime(patientVisit.start_time, 'startTime')">
            <option *ngFor="let time of startTimes" [value]="time.key">{{time.value}}</option>
          </select>
        </div>
        <div class="time">
          <label>Ավարտ</label>
          <select name="end_time" [(ngModel)]="patientVisit.end_time"
                  [ngClass]="{'validation-error': errors.endTime}"
                  (change)="changeEndTime(patientVisit.end_time, 'endTime')">
            <option *ngFor="let time of endTimes" [value]="time.key">{{time.value}}</option>
          </select>
        </div>
      </div>
    </form>

    <div class="flex-1 corners ">

      <div class="search-block">
        <label>Ծառայություն</label>
        <input class="grey" type="search" placeholder="Փնտրել պացիենտին"
               [(ngModel)]="patientSearch"
               (click)="openClosePateintList(true, $event)"
        (keyup)="search(patientSearch)"
               [ngClass]="{'validation-error': errors.patient}" >
        <i class="icon-search"> </i>
      </div>

      <div class="search spacing flex-1 corners white" *ngIf="showPateintList"
           [ngStyle]="{'top': patientListTop,'left': patientListLeft, 'width': patientListWidth}">
        <i class="icon-close fr" (click)="openClosePateintList(false)"></i>

        <a class="secondary-button mb16 mt0 background-white ra" type="submit" routerLink="/patients/create">
          <i class="icon-add"></i> Գրանցել Նոր Անկետա
        </a>

        <a class="block-link" *ngFor="let patient of patients" (click)="selectPatient(patient)">
          <div class="questionnaire-list background-white">
            {{patient.name}} {{patient.surname}} {{patient.fathername}}
          </div>
        </a>
      </div>

      <label>Ծառայություն</label>
      <input [(ngModel)]="patientVisit.treatment" type="text" name="service" style="height:64px"
             [ngClass]="{'validation-error': errors.treatment}"
             placeholder="Ծառայություն"  (keyup)="change(patientVisit.treatment, 'treatment')"/>

      <label>Նկարագրություն</label>
      <textarea [(ngModel)]="patientVisit.description" type="text" name="description" placeholder="Նկարագրություն"> </textarea>
    </div>
  </div>
</div>


<!--
  <div class="spacing flex-1 corners grey">

    <a class="block-link" href="#">
      <div class="appointment">
        <div>
          <p class="detail-description">Կոնսուլտացիա</p>
          <p class="detail-lable">Ազիզյան Գրիշա Սաշիկի · <span>19:00-21:00</span></p>
        </div>


      </div>
    </a>

    <a class="block-link" href="#">
      <div class="appointment">
        <div>
          <p class="detail-description">Կոնսուլտացիա</p>
          <p class="detail-lable">Ազիզյան Գրիշա Սաշիկի · <span>19:00-21:00</span></p>
        </div>
      </div>
    </a>
  </div>
</div>-->

<div class="action-button">
  <button type="submit" (click)="save()">Գրանցել</button>
</div>
