import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import construct = Reflect.construct;
import {Dictionary} from "../models/dictionary";

@Injectable({
    providedIn: 'root'
})
export class RestService {
    public apiUrl = 'http://api.dentist.comforthouse.am/api/';
    public headers: HttpHeaders;
    public options: any;
    public filters: any;
    private token: string;

    constructor(private httpClient: HttpClient) {
    }

    public async post<T>(url: string, formData: any, headers: Dictionary[] = [], out: T) {
        await this.setHeaders(headers);

        return this.httpClient.post(this.apiUrl + url, JSON.stringify(formData), this.getOptions())
            .pipe(map(
            (res) => {
                return Object.assign(out, res);
            }
        ));
    }

    public async put<T>(url: string, formData: FormData, headers: Dictionary[] = [], out: T) {
        await this.setHeaders(headers);

        return this.httpClient.put(this.apiUrl + url, formData, this.getOptions())
            .pipe(map(
                (res) => {
                    return Object.assign(out, res);
                }
            ));
    }

    public async get<T>(url: string, headers: Dictionary[] = [], out: T) {
        await this.setHeaders(headers);
        return this.httpClient.get(this.apiUrl + url, this.options)
            .pipe(map(
                (res) => {
                    return Object.assign(out, res);
                }
            ));
    }

    public async getFile(url: string, headers: Dictionary[] = [], responseType = 'arraybuffer') {
        await this.setHeaders(headers, responseType);
        return this.httpClient.get(this.apiUrl + url, this.options)
            .pipe(map(
                (res) => {
                    return res;
                }
            ));
    }

    public async delete<T>(url: string, headers: Dictionary[] = [], out: T) {
        await this.setHeaders(headers);
        return this.httpClient.delete(this.apiUrl + url, this.options)
            .pipe(map(
                (res) => {
                    return Object.assign(out, res);
                }
            ));
    }

    private async setHeaders(headers: Dictionary[] = [], responseType = 'json') {
        const token = localStorage.getItem('key');
        this.token = token;
        this.headers = new HttpHeaders(
          {
              Authorization: 'Bearer ' + this.token
          }
        );

        for (const header of headers) {
            if (header) {
                this.headers = this.headers.append(header.key, header.value);
            }
        }

		this.headers = this.headers.append("Content-Type", "application/json");

        this.options = {
            headers: this.headers,
            responseType: responseType
        };
    }

    public getToken() {
        return this.token;
    }

    public getOptions() {
        return this.options;
    }
}
