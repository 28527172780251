<div class="container vh100">
  <div class="spacing flex-1">
    <div>
      <img src="./images/logo.svg" alt="">
    </div>


    <div class="container-descirption">
      <h2>Բարի Գալուստ</h2>
      <h3>Մուտք գործեք ձեր անձնական էջ</h3>
    </div>

    <form class="form-fields">
      <label>էլ․ հասցե </label>
      <input type="email" name="email" [(ngModel)]="login.email" required>

      <div class="form-fields-lables"> <label>Գաղտնաբառ </label> <a href="#" class="line-link">Վերականգնե՞լ</a>
      </div>

      <div class="inputpassword">

        <input type="{{passwordInputType}}" name="password" [(ngModel)]="login.password" required>
        <a class="line-link show-hide" (click)="passwordInputType = (passwordInputType == 'password' ? 'text' : 'password')">
          {{ passwordInputType == 'password' ? 'Տեսնել' : 'Թաքցնել' }}
        </a>
      </div>

      <p class="error-message" *ngIf="showErrorMessage">
        Էլ․ հասցեն կամ գաղտնաբառը վավեր չէ: Խնդրում ենք կրկին փորձել, կամ կապ հաստաեք մեզ հետ։
      </p>

      <button type="submit" [disabled]="submitDisabled" (click)="submit()">Մուտք</button>

    </form>


    <a class="call-us" href="tel:+37493257297"> <i class="icon-call"></i> Աջակցության Կենտրոն</a>


  </div>
</div>
