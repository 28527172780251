import {Component, OnInit} from '@angular/core';
import {Globals} from "../../../models/globals";
import {Patient} from "../../../models/patient";
import {RestService} from "../../../services/rest.service";
import {Response} from "../../../models/response";
import {PatientVisit} from "../../../models/patient-visit";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-patient-create-edit',
  templateUrl: './patient-create-edit.component.html',
  styleUrls: ['./patient-create-edit.component.css']
})
export class PatientCreateEditComponent implements OnInit {

  public patient: Patient;
  public loading = false;
  public errors = {
    nsf: false,
    phone: false
  };

  constructor(public rest: RestService, private route: ActivatedRoute, private router: Router, globals: Globals) {
    globals.showMenu = true;
    globals.showHeader = true;
    this.patient = new Patient();
    this.patient.id = Number(route.snapshot.paramMap.get('id'));

    if (this.patient.id) {
      this.loading = true;
      this.rest.get(`patients/${this.patient.id}`, [], new Response<Patient>()).then(
        resp => resp.subscribe(
          data => {
            this.patient = data.item;

            this.patient.nsf = (data.item.name ? data.item.name : '') + ' '
              + (data.item.surname ? data.item.surname : '') + ' '
              + (data.item.fathername ? data.item.fathername : '');

            this.loading = false;
          },
          error => {
            this.loading = false;
            if (error.status === 401){
              this.router.navigate(['/login']);
            }
          }
        )
      );
    }
  }

  ngOnInit(): void {
  }

  save() {
    // var form_data = new FormData();

    if (!this.patient.nsf){
      this.errors.nsf = true;
    }
    if (!this.patient.phone){
      this.errors.phone = true;
    }

    if (this.errors.phone || this.errors.nsf){
      return;
    }

    const form_data = {};

    this.patient.name = this.patient.nsf.split(' ')[0];
    this.patient.surname = this.patient.nsf.split(' ')[1];
    this.patient.fathername = this.patient.nsf.split(' ')[2];

    for (const key in this.patient) {
      // form_data.append(key, this.patient[key]);
      form_data[key] = this.patient[key];
    }


    this.loading = true;
    if (!this.patient.id) {
      this.rest.post(`patients`, form_data, [], new Response<Patient>()).then(
        resp => resp.subscribe(
          data => {
            this.router.navigate(['/patients']);
          },
          error => {
            this.loading = false;
            if (error.status === 401){
              this.router.navigate(['/login']);
            }
          }
        )
      );
    } else {
      this.rest.post(`patients/${this.patient.id}`, form_data, [], new Response<Patient>()).then(
        resp => resp.subscribe(
          data => {
            this.router.navigate(['/patients']);
            this.loading = false;
          },
          error => {
            this.loading = false;
            if (error.status === 401){
              this.router.navigate(['/login']);
            }
          }
        )
      );
    }
  }

  change(value, error) {
    if (value){
      this.errors[error] = false;
    }
  }
}
