<div class="appHeader" *ngIf="config.showHeader">
  <div class="left">
    <a *ngIf="config.showBackButton" routerLink="{{config.backButtonUrl}}" class="headerButton goBack">
      <i class="fa fa-back" aria-hidden="true"></i>
      <!--<ion-icon name="chevron-back-outline"></ion-icon>-->
    </a>
  </div>
  <div class="pageTitle">
    {{config.title}}
  </div>
  <div class="right">
    <a *ngIf="config.showAddButton" routerLink="{{config.addButtonUrl}}" class="headerButton">
      <i class="fa fa-plus" aria-hidden="true"></i>
      <!--<ion-icon class="icon" name="notifications-outline"></ion-icon>
      <span class="badge badge-danger">4</span>-->
    </a>
  </div>
</div>
