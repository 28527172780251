import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {PatientVisitComponent} from "./patient-visit/patient-visit.component";
import {PatientComponent} from "./patient/patient.component";
import {PatientCreateEditComponent} from "./patient-create-edit/patient-create-edit.component";
import {AuthGuardService} from "../../services/auth-guard.service";
import {PatientVisitCreateEditComponent} from "./patient-visit-create-edit/patient-visit-create-edit.component";

const routes: Routes = [
  {
    path: 'patients',
    component: PatientComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'patients/create',
    component: PatientCreateEditComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'patients/:id/edit',
    component: PatientCreateEditComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'patient/visit',
    component: PatientVisitComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'patient/visits/create',
    component: PatientVisitCreateEditComponent,
    canActivate: [AuthGuardService]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);

