import { Component, OnInit } from '@angular/core';
import {RestService} from "../../../services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Globals} from "../../../models/globals";
import {Patient} from "../../../models/patient";
import {Response} from "../../../models/response";
import {PatientVisit} from "../../../models/patient-visit";
import {DatePipe} from "@angular/common";
import {MomentDateAdapter} from '@angular/material-moment-adapter';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import {Day} from "../../../models/day";
import {Dictionary} from "../../../models/dictionary";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYY',
  },

  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-patient-visit-create-edit',
  templateUrl: './patient-visit-create-edit.component.html',
  styleUrls: ['./patient-visit-create-edit.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PatientVisitCreateEditComponent implements OnInit {

  public patient: Patient;
  public patients: Patient[];
  public loading = false;
  public showPateintList = false;
  public minDate;
  public patientVisit: PatientVisit = new PatientVisit();
  public patientid: number;
  public timeinterval = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  public times = [];
  public startTime = 800;
  public endTime = 2300;

  public startTimes: Dictionary[];
  public endTimes: Dictionary[];

  public patientVisits: PatientVisit[];

  config = {
    firstDayOfWeek: 'su',
    // monthFormat: 'MMM, YYYY',
    disableKeypress: false,
    allowMultiSelect: false,
    closeOnSelect: undefined,
    closeOnSelectDelay: 100,
    onOpenDelay: 0,
    weekDayFormat: 'dd',
    appendTo: document.body,
    drops: 'down',
    opens: 'right',
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    format: 'DD/MM/YYYY',
    yearFormat: 'YYYY',
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ':',
    multipleYearsNavigateBy: 10,
    showMultipleYearsNavigation: false,
    locale: 'zh-am',
    minDate: { day: new Date().getDate(), month: new Date().getMonth() + 1,  year: new Date().getFullYear() }
    // min:'2017-08-29 15:50',
    // minTime:'2017-08-29 15:50'
  };

  datePickerConfig = {
    drops: 'up',
    format: 'DD/MM/YY'
  };

  public days: Day[];
  public currentDay: Day;
  public weekDays = ['Կր', 'Եկ', 'Եք', 'Չո', 'Հի', 'Ու', 'Շա'];
  public months = ['Հունվար', 'Փետրվար', 'Մարտ', 'Ապրիլ', 'Մայիս', 'Հունիս',
    'Հուլիս', 'Օգոստոս', 'Սեպտեմբեր', 'Հոկտեմբեր', 'Նոյեմբեր', 'Դեկտեմբեր'];
  public patientListTop: string;
  public patientListLeft: string;
  public patientListWidth: string;
  public patientSearch: string;
  public errors = {
    startTime: false,
    endTime: false,
    patient: false,
    treatment: false
  };

  constructor(public rest: RestService, private route: ActivatedRoute, private router: Router, globals: Globals,
              public datePipe: DatePipe) {
    globals.title = 'Ավելացնել այց';
    this.patientSearch = '';

    this.minDate = moment.now(); // datePipe.transform(new Date(), 'dd/mm/yyyy');

    function waitForElement(id, callback){
      const poops = setInterval(() => {
        if (document.getElementById(id).querySelector('.dp-picker-input')){
          clearInterval(poops);

          callback();
        }
      }, 100);
    }

    for (let i = 9; i < 24; i++){
      for (let y = 0; y < this.timeinterval.length; y++){
        this.times.push(('0' + i).slice(-2) + ':' + this.timeinterval[y]);
      }
    }

    const selectedDate = route.snapshot.queryParams.date;

    this.days = new Array<Day>();

    for (let i = 0; i < 14; i++){
      const day = new Day();
      const today = new Date();
      today.setDate(today.getDate() + i);

      day.date = today;
      day.day = today.getDate();
      day.weekDay = this.weekDays[today.getDay()];

      if (i === 0 || day.day === 1) {
        day.monthText = this.months[today.getMonth()];
      }

      if(selectedDate && selectedDate == moment(day.date).format('YYYY-MM-DD')){
        day.isActive = true;
        this.currentDay = day;
      }

      this.days.push(day);
    }

    if(!selectedDate) {
      this.days[0].isActive = true;
      this.currentDay = this.days[0];
    }

    const date = moment(this.currentDay.date).format('YYYY-MM-DD');
    this.rest.get('patient/visits?date=' + date, [], new Response<PatientVisit>()).then(
        resp => resp.subscribe(
            data => {
              this.patientVisits = data.items;

              let startTimeBusy = false;
              let endTimeBusy = false;

              this.startTimes = new Array<Dictionary>();
              for (let i = this.startTime; i <= this.endTime; i += 5 ){
                if (i % 100 < 60){

                  startTimeBusy = false;
                  for (let j = 0; j < data.items.length; j++){
                    if(i >= data.items[j].start_time && i < data.items[j].end_time){
                      startTimeBusy = true;
                      break;
                    }
                  }

                  if(!startTimeBusy){
                    const dict = new Dictionary();
                    dict.key = i.toString();
                    dict.value = this.formatTime(i);
                    this.startTimes.push(dict);
                  }
                }
              }

              /*this.endTimes = new Array<Dictionary>();
              for (let i = this.startTime; i <= this.endTime; i += 5 ){
                if (i % 100 < 60){
                  endTimeBusy = false;
                  for (let j = 0; j < data.items.length; j++){
                    if(i > data.items[j].start_time && i <= data.items[j].end_time){
                      endTimeBusy = true;
                      break;
                    }
                  }

                  if(!endTimeBusy) {
                    const dict = new Dictionary();
                    dict.key = i.toString();
                    dict.value = this.formatTime(i);
                    this.endTimes.push(dict);
                  }
                }
              }*/

            },
            error => {
              this.loading = false;
            }
        ));

    this.loading = true;
    globals.showMenu = true;
    globals.showHeader = true;
    this.patientVisit = new PatientVisit();
    this.patientVisit.day = this.currentDay.date;
    this.patientVisit.id = Number(route.snapshot.paramMap.get('id'));

    this.patientid = Number(route.snapshot.paramMap.get('patientid'));
    this.patientVisit.patient_id = this.patientVisit.id ? this.patientVisit.id : (this.patientid ? this.patientid : null);

    this.rest.get(`patients`, [], new Response<Patient>()).then(
      resp => resp.subscribe(
        data => {
          this.patients = data.items;
          console.log(this.patients);
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );

    if (this.patientVisit.id){
      this.loading = true;
      this.rest.get(`patients/${this.patientVisit.id}`, [], new Response<PatientVisit>()).then(
        resp => resp.subscribe(
          data => {
            this.patientVisit = data.item;
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        )
      );
    } else{
      this.loading = false;
    }

  }

  save(){
    const formData = {};

    if (!this.patientVisit.start_time){
      this.errors.startTime = true;
    }
    if (!this.patientVisit.end_time){
      this.errors.endTime = true;
    }
    if (!this.patientVisit.patient_id){
      this.errors.patient = true;
    }
    if (!this.patientVisit.treatment){
      this.errors.treatment = true;
    }

    if (this.errors.startTime || this.errors.endTime || this.errors.patient || this.errors.treatment){
      return;
    }

    for ( const key in this.patientVisit ) {
      if  (key === 'day'){
        const date = moment(this.patientVisit[key]).format('YYYY-MM-DD');
        formData[key] = date;
      } else {
        if (key !== 'patient') {
          formData[key] = this.patientVisit[key];
        }
      }
    }

    this.patientVisit.patient = null;
    this.loading = true;
    if (!this.patientVisit.id) {
      this.rest.post(`patient/visits`, formData, [], new Response<PatientVisit>()).then(
        resp => resp.subscribe(
          data => {
            this.router.navigate(['/patient/visit']);
          },
          error => {
            this.loading = false;
          }
        )
      );
    } else {
      this.rest.post(`patient/visits/${this.patientVisit.id}`, formData, [], new Response<PatientVisit>()).then(
        resp => resp.subscribe(
          data => {
            this.router.navigate(['/patient/visit']);
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        )
      );
    }
  }

  ngOnInit(): void {
  }

  changeDate(day: Day) {
    for (let i = 0; i < this.days.length; i++){
      this.days[i].isActive = false;
    }
    day.isActive = true;

    const date = moment(day.date).format('YYYY-MM-DD');

    this.patientVisit.day = day.date;
    this.rest.get(`patients/visits?date=${date}`, [], new Response<Patient>()).then(
      resp => resp.subscribe(
        data => {
          this.patients = data.items;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  openClosePateintList(show, event = null) {
    if (event) {
      const el = event.target.getBoundingClientRect();
      this.patientListTop = (el.top + el.height + 10).toString() + 'px';
      this.patientListLeft = (el.left - 15).toString() + 'px';
      this.patientListWidth = el.width + 'px';

    }
    this.showPateintList = show;
  }

  search(patientSearch: string) {

    let url = `patients`;

    if (patientSearch.length >= 3){
      url = `patients?q=${patientSearch}`;
    }

    this.rest.get(url, [], new Response<Patient>()).then(
      resp => resp.subscribe(
        data => {
          this.patients = data.items;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  selectPatient(patient: Patient) {
    this.errors.patient = false;

    this.patientVisit.patient_id = patient.id;
    this.patientVisit.patient = patient;

    this.patientSearch = (patient.name ? patient.name : '') + ' '
      + (patient.surname ? patient.surname : '') + ' '
      + (patient.fathername ? patient.fathername : '');

    this.showPateintList = false;
  }

  setStartTime() {
    console.log(this.patientVisit.start_time);
  }

  formatTime(time: number){
    const minute = time % 100;
    const hour = Math.floor(time / 100);

    return (hour.toString().length === 1 ? '0' + hour.toString() : hour.toString()) + ':'
      + (minute.toString().length === 1 ? '0' + minute.toString() : minute.toString());

  }

  change(value, error) {



    if (value){
      this.errors[error] = false;
    }
  }

  changeStartTime(start_time: number, startTime: string) {

    const nextVisits = this.patientVisits.filter(et => et.start_time > start_time).sort();

    let endTime = this.endTime;

    if(nextVisits.length > 0){
      endTime = nextVisits[0].start_time;
    }

    this.endTimes = new Array<Dictionary>();

    for (let i = Number(start_time) + 5; i <= endTime; i += 5 ){
      if (i % 100 < 60){
          const dict = new Dictionary();
          dict.key = i.toString();
          dict.value = this.formatTime(i);
          this.endTimes.push(dict);
      }
    }

    if (startTime){
      this.errors[startTime] = false;
    }
  }

  changeEndTime(end_time: number, endTime: string) {


    if (endTime){
      this.errors[endTime] = false;
    }
  }
}
