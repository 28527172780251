import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PatientVisitComponent} from "./patient-visit/patient-visit.component";
import {BrowserModule} from "@angular/platform-browser";
import {routing} from "./patient.routing.module";
import {CarouselModule} from "ngx-owl-carousel-o";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PatientVisit} from "../../models/patient-visit";
import {RouterModule} from "@angular/router";
import { PatientComponent } from '../patient/patient/patient.component';
import { PatientCreateEditComponent } from '../patient/patient-create-edit/patient-create-edit.component';
import { PatientVisitCreateEditComponent } from '../patient/patient-visit-create-edit/patient-visit-create-edit.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";

@NgModule({
  declarations: [PatientVisitComponent, PatientComponent, PatientCreateEditComponent, PatientVisitCreateEditComponent],
  imports: [
    CommonModule,
    BrowserModule,
    routing,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    PatientVisitComponent,
    RouterModule
  ]
})
export class PatientModule { }
