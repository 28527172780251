import {Component, OnInit} from '@angular/core';
import {Login} from "../../../models/login";
import {HttpClient} from "@angular/common/http";
import {RestService} from "../../../services/rest.service";
import {Response} from "../../../models/response";
import {TokenResponse} from "../../../models/token-response";
import {Globals} from "../../../models/globals";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public login: Login;
  public submitDisabled = false;
  public showErrorMessage = false;
  public passwordInputType = 'password';

  constructor(private rest: RestService, globals: Globals, private router: Router) {
    this.login = new Login();
    globals.showMenu = false;
    globals.showHeader = false;
  }

  ngOnInit(): void {
  }

  public async submit() {
    this.submitDisabled = true;
    this.showErrorMessage = false;
    let token = '';
    //let formData = new FormData();
    //formData.append('email', this.login.email);
    //formData.append('password', this.login.password);

    let formData = {
      "email": this.login.email,
      "password": this.login.password
    };

    (await this.rest.post('login', formData, [], new Response<TokenResponse>())).subscribe(
      data => {
        this.submitDisabled = false;
        localStorage.setItem('key', data.item.token);
        this.router.navigate(['/']);
      },
      error => {
        this.showErrorMessage = true;
        this.submitDisabled = false;
      }
    );
  }

}
